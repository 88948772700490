import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { updateMedia } from '../../../../../api/app/media'
import { useMediaItemContext } from '../../../../contexts/MediaItemProvider'

const useDuration = () => {
	/** Retrieve item from the context */
	const { item } = useMediaItemContext()

	/** Manage Mute Audio State */
	const [duration, setDuration] = useState(item.duration === 0 ? 5 : item.duration)

	useEffect(() => {
		if (item) {
			setDuration(item.duration === 0 ? 5 : item.duration)
		}
	}, [item])

	/** Retrieve dispatch method */
	const dispatch = useDispatch()

	/** Callback handler to update the mute audio */
	const handleDurationChange = useCallback(
		(time) => {
			// time = Math.min(Math.max(time, 4), 60)
			let newData = {
				id: item.id,
				mediaUuid: item.mediaUuid,
				duration: item.duration,
			}
			newData.duration = time
			newData.socketReloadPage = true
			dispatch(updateMedia(newData, false))
			setDuration(newData.duration)
		},
		[item]
	)

	return [duration, handleDurationChange]
}

export default useDuration
